




import BaseLayout from "@/components/BaseLayout.vue"
import { Component } from "vue-property-decorator"


@Component({
  components: { BaseLayout },
})
export default class RequestorView extends BaseLayout {
  private btnSaveLoading = false

}
