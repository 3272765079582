





























































import { Component, Vue } from "vue-property-decorator"
import SummaryView from "./component/SummaryView.vue"
import DatePicker from "./component/DatePicker.vue"
import MonthView from "./component/MonthView.vue"

@Component({
  components: { SummaryView, DatePicker, MonthView },
  
})
export default class Analytics extends Vue {
  private showMonth = false
  onMonthClick() {
    this.showMonth = !this.showMonth
  }
  private items = [
    {
      label: 'Total Indications',
      number: '4',
    },
    {
      label: 'In Progress',
      number: '0',
    },
    {
      label: 'Completed',
      number: '2',
    },
    {
      label: 'Open Case',
      number: '2',
    },
    {
      label: 'Total Fees',
      number: '0',
    },
    {
      label: 'Total Value',
      number: 'RM527,000.00',
    },
  ]
  private year = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  private indication= [
          { text: 'No', value: '', sortable: false },
          { text: 'Reference No / Date /Completed Date', value: '', sortable: false },
          { text: 'Status', value: '', sortable: false },
          { text: 'Commenced', value: '', sortable: false},
          { text: 'Address/ Post code', value: '', sortable: false},
          { text: 'Scheme/ Mukim', value: '', sortable: false },
          { text: 'District/ State', value: '', sortable: false },
          { text: 'Land Area/ Flr Area', value: '', sortable: false },
          { text: 'Indicative Value', value: '', sortable: false },
          { text: 'Indicated By/ Primary Valuer/ Supervising Valuer/ Authorising Valuer', value: '', sortable: false },
          { text: 'Banker/ Branch', value: '', sortable: false },
          { text: 'Contact/ Tel', value: '', sortable: false },
          { text: 'Remarks', value: '', sortable: false },
        ]
  private indicationDetail= [
    {
      status: 'Indication',
      address: 'AREA, city, SELANGOR'+ 'Maria, Affin Bank Berhad, HQ L/A:1800.00 ft B/U: 1800.00ft Type: Single Storey (1) Apartment Unit Requested: RM30,000.00 S&P: No #VR175420',
      requested: '11/01/2021 1 day, 1 hous',
      commenced: '',
      deadline: ' -',
      price: 'Min: RM150,00.00 Max: RM220,00.00',
      valuer: '',
      customer: ''
    }
  ]

}
