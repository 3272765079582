import { render, staticRenderFns } from "./MonthView.vue?vue&type=template&id=0f56dc9f&scoped=true&"
import script from "./MonthView.vue?vue&type=script&lang=ts&"
export * from "./MonthView.vue?vue&type=script&lang=ts&"
import style0 from "./MonthView.vue?vue&type=style&index=0&id=0f56dc9f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f56dc9f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
installComponents(component, {VDatePicker})
