
















































import BaseLayout from "@/components/BaseLayout.vue"
import { Component, Prop } from "vue-property-decorator"

@Component({
  components: { BaseLayout },
})
export default class SummaryView extends BaseLayout {

}
